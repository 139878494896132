import React, { useEffect, useState } from 'react';
import './Menu.css';
import { Link, useNavigate } from 'react-router-dom';
import Login from '../10. login/Login';
import Connection from '../11. connection/Connection';
import { GetAllCategoryWithDevition, GetShopCartBussiness, getAllBigMenu } from '../../axios/api';
import { useDispatch, useSelector } from 'react-redux';
import { CloseOutlined, SearchOutlined, ShoppingCartOutlined, UserOutlined, WhatsAppOutlined } from '@ant-design/icons';
import EnterManagger from '../../3. managger/1. enterManagger';
import ButtonRed from '../../global/button/button';
import Alert from '../../global/alert/Alert';
import Wait from '../../global/Wait';
import { COMPLETEallShoppingCart } from '../../2. redux/actions/shoppingCartAction';
import Search from './Search';

const Menu = () => {

    const navigate = useNavigate()
    const d = useDispatch()
    const user = useSelector((state) => state.userReducer)
    const s = JSON.parse(localStorage.getItem("CompleteMenu"));



    const [getMenu, setGetMenu] = useState([]);
    const [name, setName] = useState();
    const [wait, setWait] = useState();
    const [isBuild, setIsBuild] = useState(false);
    const [isBuildFinish, setIsBuildFinish] = useState(JSON.parse(sessionStorage.getItem('noSystemMessage')) ?? false);

    const [stateNow, setStateNow] = useState(0);
    var now = 0;
    const [isHovered, setIsHovered] = useState(false);

    const sShopping = useSelector(a => a.shoppingCartReducer.allShoppingCart)
    const [shoppingLength, setShoppingLength] = useState(0);

    useEffect(() => {
        if (!isBuildFinish)
            setTimeout(() => { if (!JSON.parse(sessionStorage.getItem('noSystemMessage'))) { setIsBuildFinish(true); setIsBuild(true); } }, 120 * 1000);
    }, []);

    useEffect(() => {
        if (user.id) {
            setName(user.lastName)
            if (sShopping.length <= 0)
                GetShopCartBussiness(user.id).then((data) => {
                    setShoppingLength(data.length)
                    d(COMPLETEallShoppingCart(data))
                }).catch((error) => { console.error(error); });
            else
                setShoppingLength(sShopping.length)
        }
    }, [user]);

    useEffect(() => {
        if (s?.length > 0)
            setGetMenu(s);
        else
            getAllBigMenu().then((data) => {
                const menuMap = new Map();

                data.forEach((row) => {
                    // מציאת או יצירת תפריט (Menu)
                    if (!menuMap.has(row.name)) {
                        menuMap.set(row.name, { name: row.name, Titles: [] });
                    }
                    const menu = menuMap.get(row.name);
                    if (row.TitleName) {
                        // מציאת או יצירת כותרת (Title)
                        let title = menu.Titles.find((t) => t.TitleName === row.TitleName);
                        if (!title) {
                            title = { TitleName: row.TitleName, Categories: [] };
                            menu.Titles.push(title);
                        }

                        // הוספת קטגוריה (Category)
                        title.Categories.push({
                            CategoryName: row.CategoryName,
                            CategoryCode: row.CategoryCode,
                            CategoryPic: row.CategoryPic,
                            SpecialFields: row.SpecialFields,
                        });
                    }
                });

                // החזרה כמערך
                data = Array.from(menuMap.values());
                setGetMenu(data);
                localStorage.setItem("CompleteMenu", JSON.stringify(data));
            }).catch((error) => { console.error(error); });
    }, []);


    // useEffect(() => {
    //     GetAllCategoryWithDevition().then((data) => {
    //         localStorage.setItem("CompleteDevition", JSON.stringify(data));
    //     }).catch((error) => { console.error(error); });
    // }, []);

    const [widthLess1300, setWidthLess1300] = useState(false);
    const [widthMore1000, setWidthMore1000] = useState(true);

    useEffect(() => {
        if (document.getElementsByClassName("mainNav")[0]) {
            setWidthLess1300(document.getElementsByClassName("mainNav")[0].offsetWidth < 1300)
            setWidthMore1000(document.getElementsByClassName("mainNav")[0].offsetWidth > 1000)
        }
    }, []);

    const [current, setCurrent] = useState(0);
    const [biUser, setBiUser] = useState(false);
    const [login, setLogin] = useState(false);
    const [connection, setConnection] = useState(false);
    const [isSearch, setIsSearch] = useState(false);
    const [alert, setAlert] = useState(false)

    const choozeMe = () => {
        for (let index = 0; index < document.getElementsByClassName('chooze1').length; index++) {
            document.getElementsByClassName('chooze1')[index].classList.remove('chooze1')
        }
        for (let index = 0; index < document.getElementsByClassName('chooze2').length; index++) {
            document.getElementsByClassName('chooze2')[index].classList.remove('chooze2')
        }
        current.classList.add('chooze1')
        current.getElementsByClassName('mainunderline')[0].classList.add('chooze2')
    }

    const [managger, setManagger] = useState()
    const [errorMessage, setErrorMessage] = useState()
    const passManaggerSubmit = () => {
        if (Number(document.getElementById('passManag').value) !== 3137663)
            setErrorMessage('סיסמה שגויה')
        else {
            setManagger(false)
            navigate(`menuManagger`)
        }
    }

    useEffect(() => {
        const handleContextMenu = (event) => {
            if (event.target.id == 'modal2')
                event.preventDefault(); // Cancels the default right-click behavior
        };

        document.addEventListener('contextmenu', handleContextMenu);

        return () => {
            document.removeEventListener('contextmenu', handleContextMenu);
        };
    }, []);

    const [send, setSend] = useState(false)

    const sendGood = () => {
        setAlert(false)
        setSend("ההודעה נשלחה בהצלחה !!!")
        setTimeout(() => {
            setSend(false)
        }, 40 * 100);
    }

    const clickUseFull = () => {
        if (document.getElementsByClassName('usefull')[0].style.display !== "block") { document.getElementsByClassName('usefull')[0].style.display = "block"; document.getElementsByClassName('usefull')[1].style.display = "block"; document.getElementsByClassName('usefull')[2].style.display = "block"; document.getElementsByClassName('usefull')[3].style.display = "block"; } else { document.getElementsByClassName('usefull')[0].style.display = "none"; document.getElementsByClassName('usefull')[1].style.display = "none"; document.getElementsByClassName('usefull')[2].style.display = "none"; document.getElementsByClassName('usefull')[3].style.display = "none"; }
    }

    useEffect(() => {
        if (biUser === true) {
            const userIcon = document.getElementById("user-icon");
            const userMenu = document.getElementsByClassName("biUser")[0];
            const rect = userIcon.getBoundingClientRect();// קבלת מיקום וגודל האייקון
            userMenu.style.left = `${rect.left}px`;// מיקום תפריט הירשם/התחבר מתחת לאייקון
        }
    }, [biUser]);



    return (<>
        {getMenu.length > 0 && <div id='userMenu'>
            {/* --------------התפריט למעלה------------- */}
            {isSearch && <Search setIsSearch={setIsSearch} getMenu={getMenu}></Search>}


            {!isSearch && <div className="scroll-container"><div className='mainNav'>
                <div className='disYes' onClick={() => { window.open(`https://wa.me/97223137663?text=${encodeURIComponent("")}`, '_blank'); }} style={{ backgroundColor: '#c00000', color: 'white', borderRadius: '50%', padding: '4px 8px', margin: '4px' }}><WhatsAppOutlined /></div>

                <Link onAuxClick={(e) => { setManagger(true) }} to="/" className="mainNav-item chooze1 animate__animated animate__rubberBand animate__infinite animate__slower"
                    onClick={(event) => {
                        choozeMe()
                        document.getElementsByClassName('chooze1')[0].classList.remove('chooze1')
                        document.getElementsByClassName('chooze2')[0].classList.remove('chooze2')
                        event.target.classList.add('chooze1')
                    }}
                    style={{ fontWeight: '400', paddingLeft: '8px', paddingRight: '15px', minWidth: 'max-content' }}>
                    קול שמחה</Link>

                {getMenu.map((item, index) => (<span key={index} style={{ fontWeight: '400', fontSize: '95%' }}>
                    <Link to={`/${item.name}/${stateNow == getMenu.length - 1 ? user.id : stateNow}`}
                        className="mainNav-item"
                        onClick={() => { setIsHovered(false); choozeMe(); }}
                        onMouseEnter={(event) => {
                            setStateNow(index);
                            setCurrent(event.currentTarget);
                            now = index; getMenu[now].Titles !== null ?
                                setTimeout(() => { setIsHovered(true); }, 2) : setIsHovered(true);
                        }}
                        onMouseLeave={() => setIsHovered(false)}>
                        {item.name}
                        <div className="mainunderline"></div>
                    </Link>
                </span>))}

                {/* <span><span className="mainNav-item" onClick={() => { clickUseFull() }}>כלים שימושיים<div className="mainunderline"></div></span>
                    <div className="usefull" style={{ top: '280px', paddingBottom: '20px' }} onClick={() => { setAlert(true) }}>יצירת קשר</div>
                </span> */}
                {user.id && <span className='mainNav-item notification' onClick={() => navigate(`/Shopping`)}>
                    <span><ShoppingCartOutlined /></span>
                    <span className="badge">{shoppingLength}</span>
                </span>}
                {name && <p className="mainNav-item" style={{ marginTop: '0px', marginBottom: '0', paddingBottom: '0', cursor: 'pointer' }} onClick={() => navigate(`/PersonalArea`)}>{name}</p>}
                <div className='mainNav-item' id='user-icon' onClick={(event) => { setBiUser(!biUser); setCurrent(event.currentTarget) }}><UserOutlined /></div>
                <div className='mainNav-item' onClick={() => { setIsSearch(true); }}><SearchOutlined /></div>
                <div className='butConcat' onClick={() => { setAlert(true) }}>יצירת קשר</div>


                {/* {!widthMore1000 && <div style={{ top: '0', height: '100%', width: '300px' }}>

                    <div style={{ width: '120px', display: 'inline-block', marginTop: '6px', marginRight: '20px', float: 'left' }} onClick={() => {
                        setAlert(true)
                    }}><ButtonRed text='יצירת קשר'></ButtonRed></div>
                </div>} */}

            </div></div>}

            {biUser && <div className="biUser" onMouseLeave={() => { setBiUser(false) }}>
                <div className="menuButtons" style={{ paddingTop: '20px', paddingLeft: '50px', paddingRight: '50px' }} onClick={() => { setBiUser(false); setLogin(!login); }}>התחבר</div><br></br>
                <div className="menuButtons" style={{ paddingBottom: '20px', paddingLeft: '50px', paddingRight: '50px' }} onClick={() => { setBiUser(false); setLogin(false); setConnection(true) }}>הירשם</div>
            </div>}
            {connection && <Connection set={setConnection}></Connection>}
            {login && <div style={{ display: 'inline-block' }}><Login setLogin={setLogin}></Login></div>}

            {/* --------------התפריט המשני------------- */}

            {isHovered && getMenu[stateNow].Titles?.length > 0 &&
                <div className="menu animate__animated animate__fadeInDown" style={{ animationDelay: '0s' }}
                    onMouseEnter={() => {
                        setIsHovered(true);
                        current.classList.add('chooze1')
                        current.getElementsByClassName('mainunderline')[0].classList.add('chooze2')
                    }}
                    onMouseLeave={() => {
                        setIsHovered(false);
                        current.classList.remove('chooze1')
                        current.getElementsByClassName('mainunderline')[0].classList.remove('chooze2')
                    }}>
                    <div className='container'>
                        {getMenu[stateNow].Titles?.map((title, titleIndex) => (
                            <div key={titleIndex}>
                                <div className='title'>{title.TitleName}
                                    <div className={`mainunderline2 ${isHovered ? "visible10" : ""}`}></div>
                                </div>
                                <div className="menu-column">
                                    {title.Categories.map((column, columnIndex) => (
                                        <div key={columnIndex} className="menu-item">
                                            <div onClick={() => {
                                                //בשביל עמוד הכרטיסים שידע האם יש שדות מיוחדים
                                                sessionStorage.setItem('SpecialFields', JSON.stringify(column.SpecialFields ?? []))
                                                navigate(`/allCards/${column.CategoryCode}/${column.CategoryName}/${getMenu[stateNow].name}`); setIsHovered(false); choozeMe()
                                            }} className="menuButtons">
                                                {column.CategoryName}
                                                <div style={{ backgroundColor: '#FF7D7D', bottom: '-5%' }} className="mainunderline"></div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>}


        </div>}

        {managger && <EnterManagger name={'קול שמחה'} errorMessage={errorMessage} set={setManagger} submit={passManaggerSubmit}></EnterManagger>}
        {alert && <Alert set={setAlert} sendGood={sendGood} text={"קול שמחה"} mail={"r0527117663@gmail.com"}></Alert>}
        {wait && <Wait></Wait>}

        {send && <div className="send animate__animated animate__bounceInUp">
            <div style={{ lineHeight: '8vh', textAlign: 'center', fontWeight: '400' }}>{send}</div>
        </div>}

        {isBuild && <div id='modal2'>
            <div className='animate__animated animate__zoomIn'>
                <div className='glow' style={{ position: 'relative', width: '500px', maxWidth: '90%', marginRight: 'auto', marginLeft: 'auto', height: 'auto' }}>
                    <CloseOutlined className='close2' onClick={() => setIsBuild(false)} style={{ top: '3%', left: '5%' }} />
                    <p className='titl' style={{ backgroundColor: '#820909', padding: '5%', marginTop: '0', color: 'white', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}>מזל טוב!</p>
                    <p className='titl' style={{ marginTop: '13%' }}>המערכת בהקמה,<br /> מזמינים אתכם לבקר שוב <br />ולראות את מאות העסקים שהצטרפו.<br /><br /> בכל חודש מצטרפים<br /> מאות עסקים חדשים.</p>
                    <div id='submit30' onClick={() => setIsBuild(false)}><ButtonRed text={'אישור'}></ButtonRed></div>
                    <br></br>
                </div>
            </div>
        </div>}
    </>
    );
};

export default React.memo(Menu);  
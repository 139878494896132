import './Forms.css';
import { useRef, useState } from 'react';
import { useEffect } from 'react';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import { GetAllCategoriesOrDevition, GetAllLogo, SendEmailNewBussiness } from '../../axios/api';
import logo from '../../../pic/logo/11.jpg';

const FormNewBusiness = (prop) => {

    const logoSelector = JSON.parse(localStorage.getItem("COMPLETElogo"));
    const [allLogo, setAllLogo] = useState()
    const [send, setSend] = useState(false)
    const [another, setAnother] = useState(false)
    const [sendGood, setSendGood] = useState(false)
    const [think, setThink] = useState(false)
    const baseImageUrl = "https://kolsimcha.co.il/images";
    const didExios = useRef(false);

    useEffect(() => {
        if (logoSelector?.length > 0) {
            setAllLogo(logoSelector)
        }
        else if (!didExios.GetAllLogo) {  // רק אם טרם התבצע
            didExios.GetAllLogo = true;
            GetAllLogo().then((data) => {
                setAllLogo(data)
                localStorage.setItem("COMPLETElogo", JSON.stringify(data));
            }).catch((error) => { console.error(error); });
        }
    }, []);

    //--------------------------------start form---------------------------------
    const allCategoriesOrDevition = JSON.parse(localStorage.getItem("AllCategoriesOrDevition"));
    const [top100Films, setTop100Films] = useState([]);
    useEffect(() => {
        if (allCategoriesOrDevition?.length > 0) {
            setTop100Films([{ name: 'תחום:' }, ...allCategoriesOrDevition, { name: 'אחר' }])
        }
        else {
            GetAllCategoriesOrDevition().then((data) => {
                var arr = Array.from(new Set(data.map(obj => obj.name))).map(name => data.find(obj => obj.name === name))
                setTop100Films([{ name: 'תחום:' }, ...arr, { name: 'אחר' }])
                localStorage.setItem("AllCategoriesOrDevition", JSON.stringify(data));
            }).catch((error) => { console.error(error); });
        }
    }, []);

    const [newBusiness, setNewBusiness] = useState({ name: '', option: '', phoneNumber: '', email: '' });
    const [errorMessage, setErrorMessage] = useState('');

    const handleNameChange = (e) => {
        const inputValue = e.target.value;
        // Validate: Only letters allowed
        if (/^[A-Za-zא-ת ]+$/.test(inputValue) || inputValue === '') {
            setNewBusiness({ name: inputValue, option: newBusiness.option, phoneNumber: newBusiness.phoneNumber, email: newBusiness.email });
        }
    };

    const handleAnotherChange = (e) => {
        const inputValue = e.target.value;
        // Validate: Only letters allowed
        if (/^[A-Za-zא-ת ]+$/.test(inputValue) || inputValue === '') {
            setAnother({ one: true, two: inputValue })
        }
    };

    const handleOptionChange = (e) => {
        const inputValue = e.target.value;
        if (inputValue === "אחר")
            setAnother({ one: true, two: '' })
        else {
            setAnother({ one: false, two: '' })
        }
        // Validate: Existing options or custom input
        setNewBusiness({ name: newBusiness.name, option: inputValue, phoneNumber: newBusiness.phoneNumber, email: newBusiness.email });
    };

    const handlePhoneNumberChange = (e) => {
        const inputValue = e.target.value;
        // Validate: Numeric input and valid phone number format
        if (/^[0-9]*$/.test(inputValue) || inputValue === '') {
            setNewBusiness({ name: newBusiness.name, option: newBusiness.option, phoneNumber: inputValue, email: newBusiness.email });
        }
    };

    const handleEmailChange = (e) => {
        const inputValue = e.target.value;
        // Validate: English letters, @ and - symbols, and valid email format
        if (/^[א-תA-Za-z@.0-9]+$/.test(inputValue) || inputValue === '') {
            setNewBusiness({ name: newBusiness.name, option: newBusiness.option, phoneNumber: newBusiness.phoneNumber, email: inputValue });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage('');

        // Check if all fields are filled
        if (newBusiness.name === '' || newBusiness.option === '' || newBusiness.phoneNumber === '' || newBusiness.email === '') {
            setErrorMessage('כל השדות הן חובה');
        } else {
            // Reset error message
            setErrorMessage('');

            // Validate phone number
            if (!/^\d{10}$/.test(newBusiness.phoneNumber)) {
                setErrorMessage('מספר נייד לא תקין');
                return;
            }

            // Validate email
            else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(newBusiness.email)) {
                setErrorMessage('כתובת מייל לא תקינה');
                return;
            }

            else {
                setThink(true)
                var mes = `<div style="font-family: Calibri, Rubik, Tahoma, Comic Sans MS, sans-serif; font-size: 24px; direction: rtl; padding-bottom: 10vh; text-align: center; background-color: rgb(245, 239, 239);"><div style="background-color: rgb(232, 219, 219); padding: 20px; padding-right: 20%; padding-left: 20%; border-top: #c00000 1px solid; border-bottom: #c00000 1px solid;"><p style="font-size: 130%;">שלום לקול שמחה</p></div><br/> <br/> עסק חדש רוצה להצטרף  <br/> <br/> <b>שם העסק: </b> ${newBusiness.name}. <br/><b>תחום: </b> ${newBusiness.option}<br/> <b>מספר טלפון: </b> ${newBusiness.phoneNumber}<br/> <b>כתובת מייל: </b> ${newBusiness.email}<br/><br/> <br/><b>קול שמחה - הכל לשמחה</b> <br/> <br/> <a href="https://kolsimcha.co.il" style="text-decoration: none; background: linear-gradient(90deg, #c00000, #e57373); color: white; padding: 15px 30px; font-weight: bold; border-radius: 5px; box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);">מעבר לאתר</a> <br/></div>`
                SendEmailNewBussiness({ name: '', email: newBusiness.email, subject: "עסק חדש רוצה להצטרף", message: mes }).then((data) => {
                    setSendGood(true)
                    setThink(false)
                    if (prop.show) {
                        setSend("ההודעה נשלחה בהצלחה")
                        setNewBusiness({ name: '', option: '', phoneNumber: '', email: '' })
                        setTimeout(() => {
                            setSend(false)
                        }, 40 * 100);
                    }
                }).catch((error) => {
                    console.error(error);
                    setSend("אירעה תקלה, ההודעה לא נשלחה, יש לנסות שנית---")
                    setThink(false)
                    setTimeout(() => {
                        setSend(false)
                    }, 40 * 100);
                });

            }
        }
    };


    //--------------------------------end form---------------------------------
    const ref2 = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible3')
                }
            });
        });

        if (ref2.current) { observer.observe(ref2.current); }

        return () => {
            if (ref2.current) { observer.unobserve(ref2.current); }
        };
    }, []);

    return <div>
        {prop.show != true && <div><div className='glow' style={{ width: '80%', height: `${another.one ? '450px' : '300px'}`, borderRadius: '20px 20px 20px 20px', zIndex: '100', border: '#c00000 3px solid', marginRight: '10%', marginTop: '15vh', minHeight: 'max-content' }}>
            {!sendGood && <><div className='titl' style={{ marginTop: '5%', fontSize: '120%' }}><div style={{ display: 'inline-block', color: '#c00000' }}>עסק חדש -</div> לא הצלחת להצטרף לחבילה באופן עצמאי?<br></br> מלא את הפרטים ונחזור אליך בהקדם</div>

                <form className='newBusinessForm' onSubmit={handleSubmit} style={{ marginTop: '5%' }}>
                    <input className='input' placeholder="שם העסק" autoComplete="on" type="text" value={newBusiness.name} onChange={handleNameChange} />
                    {top100Films && <select className='input' placeholder="תחום" autoComplete="on" value={newBusiness.option} onChange={handleOptionChange}>
                        {top100Films.map((x, i) => (
                            <option key={i} value={x.name} style={{ fontWeight: `${i == 0 ? '400' : '300'}` }}>{x.name}</option>
                        ))}
                    </select>}
                    <input className='input' placeholder="נייד" autoComplete="on" type="text" value={newBusiness.phoneNumber} onChange={handlePhoneNumberChange} />
                    <input className='input' placeholder="דואר אלקטרוני" autoComplete="on" type="email" value={newBusiness.email} onChange={handleEmailChange} />
                    <span style={{ cursor: `${think ? 'wait' : 'pointer'}` }}><button className='input' id='submit' type="submit" style={{ pointerEvents: `${think ? 'none' : 'auto'}` }}>צרו איתי קשר</button></span>
                    {another.one && <input className='input' style={{ width: '85%' }} placeholder="מהו התחום האחר אליו אתם שייכים?" autoComplete="on" type="text" value={another.two} onChange={handleAnotherChange} />}
                    {errorMessage && <p><p style={{ marginLeft: '0.5%', color: '#FFC000', display: 'inline-block', marginTop: '-10px' }}><AnnouncementIcon /></p>{errorMessage}</p>}

                </form></>}
            {sendGood && <div>
                <img loading='lazy' src={logo} style={{ width: '23%' }}></img>
                <div style={{ fontSize: '140%' }}>ההודעה נשלחה בהצלחה</div>
            </div>}
        </div>
            <p style={{ fontSize: '150%', fontWeight: '500', marginTop: '7%' }}>הספקים שעובדים איתנו</p>
            <div ref={ref2} style={{ transition: 'width 0.5s ease-in-out' }} className='underline3'></div>
            {allLogo?.length > 0 && <div style={{ width: '80%', marginRight: 'auto', marginLeft: 'auto' }}>{allLogo.filter(x => x.length > 10).map((x, i) => (
                <div key={i} id={`logo${i}`} style={{ width: '135px', height: '90px', margin: '5px', display: 'inline-flex', justifyContent: 'center', backgroundColor: 'ButtonFace', borderRadius: '5px', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.3)' }}>
                    <img loading='lazy' style={{ maxWidth: '135px', maxHeight: '90px', borderRadius: '5px' }} src={`${baseImageUrl}${x.replace(/\\/g, '\/')}?format=avif`} alt={'x'} onError={() => document.getElementById(`logo${i}`).style.display = 'none'}></img>
                </div>
            ))}</div>}
        </div>}




        {prop.show && <form className='newBusinessForm' onSubmit={handleSubmit} style={{ display: 'inline-block' }}>
            <input className='input input1' placeholder="שם העסק" autoComplete="on" type="text" value={newBusiness.name} onChange={handleNameChange} />
            {top100Films && <select className='input input1' placeholder="תחום" autoComplete="on" value={newBusiness.option} onChange={handleOptionChange}>
                {top100Films.map((x, i) => (
                    <option key={i} value={x.name}>{x.name}</option>
                ))}
            </select>}
            <input className='input input1' placeholder="נייד" autoComplete="on" type="text" value={newBusiness.phoneNumber} onChange={handlePhoneNumberChange} />
            <input className='input input1' placeholder="דואר אלקטרוני" autoComplete="on" type="email" value={newBusiness.email} onChange={handleEmailChange} />
            <span style={{ cursor: `${think ? 'wait' : 'pointer'}` }}><button className='input input1 submit1' id='submit submit1' type="submit" style={{ pointerEvents: `${think ? 'none' : 'auto'}` }}>צרו איתי קשר</button></span>
            {another.one && <input className='input' style={{ width: '100%' }} placeholder="מהו התחום האחר אליו אתם שייכים?" autoComplete="on" type="text" value={another.two} onChange={handleAnotherChange} />}

            {errorMessage && <p><p style={{ marginLeft: '0.5%', color: '#FFC000', display: 'inline-block', marginTop: '-10px' }}><AnnouncementIcon /></p>{errorMessage}</p>}

        </form>}

        {send && <div className="send animate__animated animate__bounceInUp">
            <div style={{ lineHeight: '8vh', textAlign: 'center', fontWeight: '400', color: 'black' }}>{send}</div>
        </div>}
    </div>
}

export default FormNewBusiness
import ppic from '../../../pic/flowers.jpg'
import { createRef, useEffect, useRef, useState } from 'react';
import BottomMenu from '../bottomMenu/BottomMenu';
import { useSelector } from 'react-redux';
import Wait from '../Wait';
import { styled } from 'styled-components';
import './Image.css'
import { useNavigate } from 'react-router-dom';
import RefReady from '../ref';

const ImgDiv = styled.div`
        background-image: url(${props => props.pic}); 
    `;

const PicForMenu = (props) => {
    useEffect(() => { window.scrollTo(0, 0); }, []);

    const s = JSON.parse(localStorage.getItem("CompleteMenu"))[props.now];
    const [menu, setMenu] = useState([])
    const [wait, setWait] = useState(false)
    const baseImageUrl = "https://kolsimcha.co.il/images";
    const navigate = useNavigate()

    useEffect(() => {
        if (s)
            setMenu(s)
    }, [s]);

    // ----------------------start ref--------------------------
    const lineRefs = useRef([]);

    useEffect(() => {
        if (menu.length > 0) {
            lineRefs.current = menu?.Titles?.map((_, i) => lineRefs.current[i]?.current ?? createRef());

            const observer = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('visible3')
                    }
                });
            });

            for (let index = 0; index < menu?.Titles?.length; index++) {
                if (lineRefs.current[index]) { observer.observe(lineRefs.current[index].current) }
            }

            return () => {
                for (let index = 0; index < menu?.Titles?.length; index++) {
                    if (lineRefs.current[index] && lineRefs.current[index].current) {
                        observer.unobserve(lineRefs.current[index].current)
                    }
                }
            };
        }
    }, [menu]);
    // ----------------------end ref--------------------------

    return <div>

        <p style={{ fontSize: '90%', fontWeight: '300', marginTop: '0', color: '#c00000', float: 'right', marginRight: '12.1%', cursor: 'pointer' }}>{menu?.name} &#8592;</p>
        {menu?.Titles?.length > 1 && <div style={{ textAlign: 'center', width: '98%', marginRight: '1%', position: 'fixed', backgroundColor: 'white', zIndex: '400' }}>{menu?.Titles?.map((item, i) => (
            <a key={i} className='buttonKishur' href={`#tit${i}`} style={{ cursor: 'pointer', display: 'inline-block', width: `${100 / menu?.Titles?.length - 1}%`, margin: '0.5%', marginTop: '60px', padding: '5px', textDecoration: 'none' }}>{item.TitleName}</a>
        ))}</div>}

        {menu?.Titles?.length > 0 && menu?.Titles?.map((item, i) => (
            <div key={i}>
                <p className='title789' id={`tit${i}`} style={{ fontSize: '150%', fontWeight: '500' }}>{item.TitleName}</p>
                <div ref={lineRefs.current[i]} style={{ transition: 'width 0.5s ease-in-out' }} className='underline3'></div>
                {item.Categories.map((x, index) => (
                    <div key={index} style={{ display: 'inline-block', marginRight: '1%' }}>
                        <RefReady tag={<div className='cardImg'>
                            <ImgDiv pic={x.CategoryPic ? `${baseImageUrl}${x.CategoryPic.replace(/\\/g, '\/')}?format=avif` : `${ppic}?format=webp`} className='img' onClick={() => {
                                //����� ���� �������� ���� ��� �� ���� �������
                                sessionStorage.setItem('SpecialFields', JSON.stringify(x.SpecialFields ?? []))
                                navigate(`/allCards/${x.CategoryCode}/${x.CategoryName}/${menu?.name}`)
                            }}></ImgDiv>
                            <div id='txt'>{x.CategoryName}</div>
                        </div>} event={'animate__lightSpeedInRight'}></RefReady>
                    </div>
                    // <ImageStyle key={index} text={x.CategoryName} pic={x.CategoryPic ? `${baseImageUrl}${x.CategoryPic.replace(/\\/g, '\/')}?format=avif` : `${ppic}?format=avif`} categoryId={x.CategoryCode} categoryName={menu?.name}></ImageStyle>
                ))}
            </div>
        ))}

        <div style={{ width: '100%', height: '5%', marginTop: '15%' }}></div>

        <BottomMenu></BottomMenu>
        {wait && <Wait></Wait>}
    </div>
}

export default PicForMenu
import { useDispatch, useSelector } from "react-redux";
import ButtonRed from "../global/button/button"
import RefReady from "../global/ref"
import { COMmanaggerSTATISTIC } from "../2. redux/actions/managgerActions";
import { useEffect, useState } from "react";
import { ChangePassBussiness, EditHowManyCall, EditNumToCall, EditPhoneToCall, GetBussinessToManaggerStatistic, GetBusStatistic, GetBusStatisticForMonth, GetOneBussiness, SendEmailToBussiness } from "../axios/api";
import Search from "./Search";
import { BarChartOutlined, CloseOutlined, HeatMapOutlined, PieChartOutlined, RiseOutlined, SearchOutlined } from "@ant-design/icons";
import { CompleteThisBussiness } from "../2. redux/actions/thisBussinessActions";
import { styled } from 'styled-components';
import Alert from "../global/alert/Alert";
import pv from "../../pic/animations/65.gif"

const ImageDiv = styled.div`
        background-image: url(${props => props.$pic}); 
    `;

const Statistics = () => {

    const [nav, setNav] = useState(0)
    const [statistic, setStatistic] = useState([]);
    const [tempStatistic, setTempStatistic] = useState([]);
    const d = useDispatch()
    const statisticSelector = useSelector(a => a.managgerReducer.statistic)
    const [openShlucha, setOpenShlucha] = useState(false);
    const [openTdHowManyCall, setOpenTdHowManyCall] = useState(false);
    const [openPhoneToCall, setOpenPhoneToCall] = useState(false);
    const [bus, setBus] = useState(0)
    const [busToNav3, setBusToNav3] = useState([])
    const [busToNav3Good, setBusToNav3Good] = useState({})
    const [inpNav3, setInpNav3] = useState()
    const [alert, setAlert] = useState(false);
    const [mailForOpenShlucha, setMailForOpenShlucha] = useState(-1);
    const [busToNav4, setBusToNav4] = useState([])


    // const emptyString = ""
    const baseImageUrl = "https://kolsimcha.co.il/images";

    const [shlucha, setShlucha] = useState('');

    const [openCode, setOpenCode] = useState(false);
    const [code, setCode] = useState('');

    const [openDel, setOpenDel] = useState(false);
    const workerOrManagger = sessionStorage.getItem('managger')

    useEffect(() => {
        if (statisticSelector.length > 0) {
            setStatistic(statisticSelector)
            setTempStatistic(statisticSelector)
        }
        else {
            GetBussinessToManaggerStatistic().then((data) => {
                data.sort((x, y) => x.id - y.id);
                setStatistic(data)
                setTempStatistic(data)
                d(COMmanaggerSTATISTIC(data))
            }).catch((error) => { console.error(error); });
        }
    }, []);

    const nav3ChooseBus = (e) => {
        var busGood = statistic.filter(a => a.name === e.target.value)
        if (busGood.length === 1)
            GetBusStatistic(String(busGood[0].categoryCode).split(',')[0], busGood[0].id).then((data) => {
                setBusToNav3Good(busGood[0]); setBusToNav3(data);
                e.target.blur();
                e.target.classList.add('inpToNav3Enter')
            });
        else if (busGood.length > 1) {
            setBusToNav3Good(busGood);
            e.target.blur();
            e.target.classList.remove('inpToNav3Enter')
        }
        else { setBusToNav3Good({}); setBusToNav3([]); e.target.blur(); e.target.classList.remove('inpToNav3Enter'); setInpNav3(e.target.value) }
    }

    const nav3ChooseBus2 = (x) => {
        var busGood = statistic.filter(a => a.id === x.id)
        GetBusStatistic(String(busGood[0].categoryCode).split(',')[0], busGood[0].id).then((data) => {
            setBusToNav3Good(busGood[0]); setBusToNav3(data);
            document.getElementsByClassName('inpToNav3')[0].classList.add('inpToNav3Enter')
        });
    }

    const nav4ChooseMonth = (year, month) => {
        if (!year || !month) return;
        const yearNum = parseInt(year), monthNum = parseInt(month);
        if (isNaN(yearNum) || isNaN(monthNum)) return;
        if (yearNum < 1900 || yearNum > 2100) return;
        if (monthNum < 1 || monthNum > 12) return;
        var theDate = new Date(Date.UTC(yearNum, monthNum - 1, 1, 0, 0, 0))
        GetBusStatisticForMonth(theDate.toISOString()).then((data) => {
            setBusToNav4(data);
            document.getElementsByClassName('inpToNav3')[1].classList.add('inpToNav3Enter')
        });
    }

    return <div>

        <div id="mySidenav1" className="sidenav1">
            <div id="about" onClick={() => setNav(1)}><RiseOutlined className='icons' />מידע בסיסי על כל עסק</div>
            <div id="blog" onClick={() => setNav(2)}><BarChartOutlined className='icons' />מידע לקו הטלפוני</div>
            <div id="projects" onClick={() => setNav(3)}><PieChartOutlined className='icons' />כמות צפיות ופניות לעסק מסויים</div>
            <div id="contact" onClick={() => setNav(4)}><HeatMapOutlined className='icons' />סטטיסטיקה לפי חודשים</div>
        </div>

        {nav === 0 && <div>
            <p style={{ fontSize: '150%', fontWeight: '500', marginTop: '8%', width: '100%', marginBottom: '0px' }}>מערכת ניהול וסטטיסטיקה <p style={{ fontSize: '60%', display: 'inline-block' }}>({statistic.length} עסקים)</p></p>
            <RefReady tag={<div className='animate__animated' style={{ width: '70px', height: '4px', backgroundColor: '#c00000', marginLeft: 'auto', marginRight: 'auto', marginBottom: '3%' }}></div>} event={'animate__lightSpeedInRight'}></RefReady>

            <div className="ribuha">
                <div className="inRibuha">מידע בסיסי על כל עסק</div>
                <div className="inRibuhaDown"><input className="buttonInRibuhaDown" type="button" value="הבא" onClick={() => { setNav(1) }} style={{ minWidth: 'max-content', width: '150px', paddingRight: '7px', paddingLeft: '7px', borderRadius: '5px 5px 5px 5px', marginRight: 'auto', marginLeft: 'auto', cursor: 'pointer', fontWeight: '300', display: 'inline-block', marginTop: '3vh' }} /></div>
            </div>

            <div className="ribuha">
                <div className="inRibuha">מידע לקו הטלפוני</div>
                <div className="inRibuhaDown"><input className="buttonInRibuhaDown" type="button" value="הבא" onClick={() => { setNav(2) }} style={{ minWidth: 'max-content', width: '150px', paddingRight: '7px', paddingLeft: '7px', borderRadius: '5px 5px 5px 5px', marginRight: 'auto', marginLeft: 'auto', cursor: 'pointer', fontWeight: '300', display: 'inline-block', marginTop: '3vh' }} /></div>
            </div>

            <div className="ribuha">
                <div className="inRibuha">כמות צפיות ופניות לעסק מסויים</div>
                <div className="inRibuhaDown"><input className="buttonInRibuhaDown" type="button" value="הבא" onClick={() => { setNav(3) }} style={{ minWidth: 'max-content', width: '150px', paddingRight: '7px', paddingLeft: '7px', borderRadius: '5px 5px 5px 5px', marginRight: 'auto', marginLeft: 'auto', cursor: 'pointer', fontWeight: '300', display: 'inline-block', marginTop: '3vh' }} /></div>
            </div>

            <div className="ribuha">
                <div className="inRibuha">סטטיסטיקה לפי חודש</div>
                <div className="inRibuhaDown"><input className="buttonInRibuhaDown" type="button" value="הבא" onClick={() => { setNav(4) }} style={{ minWidth: 'max-content', width: '150px', paddingRight: '7px', paddingLeft: '7px', borderRadius: '5px 5px 5px 5px', marginRight: 'auto', marginLeft: 'auto', cursor: 'pointer', fontWeight: '300', display: 'inline-block', marginTop: '3vh' }} /></div>
            </div>
        </div>}

        {nav === 1 && <div>
            <Search funcSearch={(inp) => { setStatistic(tempStatistic.filter(a => a.name?.includes(inp) || a.categoryCode?.includes(inp) || a.phone?.includes(inp) || a.mail?.includes(inp) || a.city?.includes(inp))); window.scrollTo(0, 0); }} txt={'בטבלת מידע על עסקים'}></Search>
            <p style={{ fontSize: '150%', fontWeight: '500', marginTop: '8%', width: '100%', marginBottom: '-10px' }}>מידע בסיסי על כל עסק <p style={{ fontSize: '60%', display: 'inline-block' }}>({statistic.length} עסקים)</p></p>
            <RefReady tag={<div className='animate__animated' style={{ width: '70px', height: '4px', backgroundColor: '#c00000', marginLeft: 'auto', marginRight: 'auto', marginBottom: '3%' }}></div>} event={'animate__lightSpeedInRight'}></RefReady>

            {statistic && <table style={{ width: '90%', marginRight: '5%', textAlign: 'center' }} className="animate__animated animate__fadeInUp">
                <thead>
                    <tr>
                        <th style={{ fontWeight: '400', maxWidth: '2%', textAlign: 'center' }}></th>
                        <th style={{ fontWeight: '400', maxWidth: '20%', textAlign: 'center' }}>שם העסק</th>
                        {workerOrManagger === '0' && <th style={{ fontWeight: '400', maxWidth: '10%', textAlign: 'center' }}>קוד ניהול</th>}
                        <th style={{ fontWeight: '400', maxWidth: '20%', textAlign: 'center' }}>תחום</th>
                        <th style={{ fontWeight: '400', maxWidth: '10%', textAlign: 'center' }}>מספר טלפון</th>
                        <th style={{ fontWeight: '400', maxWidth: '25%', textAlign: 'center' }}>כתובת מייל</th>
                        <th style={{ fontWeight: '400', maxWidth: '13%', textAlign: 'center' }}>עיר / אזור</th>
                    </tr>
                </thead>
                <tbody>
                    {statistic.map((bussiness, i) => (
                        <tr style={{ fontWeight: '300', textAlign: 'center', borderTop: '#c00000 1px solid', backgroundColor: `${bussiness.code === -1 ? '#555' : 'white'}` }} key={i}>
                            <td style={{ textAlign: 'center', borderLeft: '#c00000 1px solid' }}>{i + 1}</td>
                            <td style={{ textAlign: 'center', width: '20%', height: '100px', position: 'relative', padding: '0' }}>
                                <img src={encodeURI(baseImageUrl + bussiness.mainImage?.replace(/\\/g, '/'))} alt="" loading="lazy" style={{ width: '100%', height: '100px', objectFit: 'cover', borderRadius: '5px', padding: '0' }} />
                                <p style={{ backgroundColor: 'white', paddingRight: '7px', paddingLeft: '7px', borderRadius: '5px 5px 5px 5px', marginRight: 'auto', marginLeft: 'auto', cursor: 'pointer', position: 'absolute', top: "50%", left: "50%", transform: "translate(-50%, -100%)" }} className="tdHowManyCall" onClick={() => { if (bussiness.code !== -1) { setOpenDel({ bussiness: bussiness, i: i }) } }}>{bussiness.name} {bussiness.code === -1 ? '- עסק כבוי' : ''}</p></td>
                            {workerOrManagger === '0' && <td style={{ textAlign: 'center' }}>{bussiness.code}</td>}
                            <td style={{ textAlign: 'center' }}>{bussiness.categoryCode}</td>
                            <td style={{ textAlign: 'center', direction: 'ltr' }}>{bussiness.phone}</td>
                            <td style={{ textAlign: 'center', direction: 'ltr' }}><a href={`mailto:${bussiness.mail}`}>{bussiness.mail}</a></td>
                            <td style={{ textAlign: 'center', direction: 'ltr' }}>{bussiness.city}</td>
                        </tr>
                    ))}
                </tbody>
            </table>}
        </div>}

        {nav === 2 && <div>
            <Search funcSearch={(inp) => { setStatistic(tempStatistic.filter(a => a.name?.includes(inp) || a.phoneToCall?.includes(inp))); window.scrollTo(0, 0); }} txt={'בטבלת הסטטיסטיקה'}></Search>
            <p style={{ fontSize: '150%', fontWeight: '500', marginTop: '8%', width: '100%', marginBottom: '-10px' }}>מידע לקו הטלפוני <p style={{ fontSize: '60%', display: 'inline-block' }}>({statistic.length} עסקים)</p></p>
            <RefReady tag={<div className='animate__animated' style={{ width: '70px', height: '4px', backgroundColor: '#c00000', marginLeft: 'auto', marginRight: 'auto', marginBottom: '3%' }}></div>} event={'animate__lightSpeedInRight'}></RefReady>

            {statistic && <table style={{ width: '90%', marginRight: '5%', textAlign: 'center' }} className="animate__animated animate__fadeInUp">
                <thead>
                    <tr>
                        <th style={{ fontWeight: '400', maxWidth: '2%', textAlign: 'center' }}></th>
                        <th style={{ fontWeight: '400', maxWidth: '20%', textAlign: 'center' }}>שם העסק</th>
                        <th style={{ fontWeight: '400', maxWidth: '2%', textAlign: 'center' }}>מספר מזהה לעסק</th>
                        {/* <th style={{ fontWeight: '400', maxWidth: '10%', textAlign: 'center' }}>כמה נכנסו</th> */}
                        {/* <th style={{ fontWeight: '400', maxWidth: '10%', textAlign: 'center' }}>כמה שלחו מייל</th> */}
                        <th style={{ fontWeight: '400', maxWidth: '20%', textAlign: 'center' }}>סדר העסקים להציג באתר:<br></br> מספר גבוה = ראשון</th>
                        <th style={{ fontWeight: '400', maxWidth: '20%', textAlign: 'center' }}>טלפון למערכת הטלפונית</th>
                        <th style={{ fontWeight: '400', maxWidth: '25%', textAlign: 'center' }}>מס' שלוחה</th>
                        {/* <th style={{ width: '10%' }}></th> */}
                    </tr>
                </thead>
                <tbody>
                    {statistic.map((bussiness, i) => (
                        <tr style={{ fontWeight: '300', textAlign: 'center', borderTop: '#c00000 1px solid', backgroundColor: `${bussiness.code === -1 ? '#555' : 'white'}` }} key={i}>
                            <td style={{ textAlign: 'center', borderLeft: '#c00000 1px solid' }}>{i + 1}</td>
                            <td style={{ textAlign: 'center', width: '20%', height: '100px', position: 'relative', padding: '0' }}>
                                <img src={encodeURI(baseImageUrl + bussiness.mainImage?.replace(/\\/g, '/'))} alt="" loading="lazy" style={{ width: '100%', height: '100px', objectFit: 'cover', borderRadius: '5px', padding: '0' }} />
                                <p style={{ backgroundColor: 'white', paddingRight: '7px', paddingLeft: '7px', borderRadius: '5px 5px 5px 5px', marginRight: 'auto', marginLeft: 'auto', cursor: 'pointer', position: 'absolute', top: "50%", left: "50%", transform: "translate(-50%, -100%)" }} className="tdHowManyCall" onClick={() => { if (bussiness.code !== -1) { setOpenDel({ bussiness: bussiness, i: i }) } }}>{bussiness.name} {bussiness.code === -1 ? '- עסק כבוי' : ''}</p></td>
                            <td style={{ textAlign: 'center' }}>{bussiness.id}</td>
                            {/* <td style={{ textAlign: 'center' }}>{bussiness.howManySeeYou}</td> */}
                            {/* <td style={{ textAlign: 'center' }}>{bussiness.howManySendEmail}</td> */}
                            <td style={{ textAlign: 'center', cursor: 'pointer', userSelect: 'none' }} className="tdHowManyCall" onClick={() => { setOpenTdHowManyCall({ bussiness: bussiness, i: i }) }}>{bussiness.orderBy}</td>
                            <td style={{ textAlign: 'center' }}>{bussiness.code != -1 ? <div style={{ cursor: 'pointer' }} className="tdHowManyCall" onClick={() => setOpenPhoneToCall({ bussiness: bussiness, i: i })}>{bussiness.phoneToCall}</div> : <div className='divButton divButton2' onClick={() => setOpenCode({ bussiness: bussiness, i: i })}>הפעל עסק</div>}</td>
                            <td style={{ textAlign: 'center', direction: 'ltr' }}>{bussiness.numberToCall != null ? <div style={{ cursor: 'pointer' }} className="tdHowManyCall" onClick={() => { setOpenShlucha({ bussiness: bussiness, i: i }); setShlucha(bussiness.numberToCall) }}>{bussiness.numberToCall ? bussiness.numberToCall : 'הקימו שלוחה'}</div> : bussiness.phoneToCall && bussiness.code != -1 && bussiness.numberToCall === null ? <div className='divButton divButton2' onClick={() => setOpenShlucha({ bussiness: bussiness, i: i })}>נדרש למלא</div> : ''}</td>
                            {/* <td><IconButton align="left" className='IconButton' size="small" onClick={() => { if (open.includes(i)) { setOpen(open.filter((x) => x != i)); document.getElementsByTagName("tr")[num - 1].classList.remove("open"); document.getElementsByTagName("tr")[num - 1].classList.add("class3") } else setOpen([...open, i]) }}>
                            {open.includes(i) ? <CloseOutlined /> : <PlusOutlined />}
                        </IconButton></td> */}
                        </tr>
                    ))}
                </tbody>
            </table>}

            <div style={{ textAlign: 'right', marginRight: '10%', width: '65%', marginTop: '5%' }}><b>איך מורידים עסק לחבילה נמוכה יותר?</b>
                <li>בלחיצה על שם העסק ניתן לבטל את העסק לגמרי - ניתן לעשות זאת אך ורק עפ"י בקשת העסק או בעקבות אי תשלום.</li>
                <li>מחבילת קול מצהלות לחבילת קול שמחה - עדיין לא פעיל - צריך למחוק / לכבות את המוצרים של העסק - יש לברר אצל העסק האם ללמחוק לגמרי את כל המוצרים או שאולי ירצה בעתיד להצטרף בחזרה ולשמור לו עדיין את פרטי המוצרים שהכניס, ולפעול בהתאם.</li>
                <li>מחבילת קול שמחה לחבילת קול ששון - מוחקים את מספר הטלפון מעמודה: טלפון למערכת הטלפונית.</li>
            </div>
        </div>}

        {nav === 3 && <div>
            <div style={{ fontSize: '150%', fontWeight: '500', marginTop: '8%', width: '100%', marginBottom: '-10px' }}>בחרו עסק:
                <input style={{ display: 'inline-block', width: '20%', fontSize: '80%', cursor: 'auto', margin: '0 20px' }} className='inpToNav3' type='text' placeholder='שם העסק' autoComplete="off" autoCapitalize="off" onKeyDown={(e) => { if (e.key === "Enter") { nav3ChooseBus(e) } }}></input>
                <p style={{ fontSize: '60%', display: 'inline-block' }}>({statistic.length} עסקים)</p>
            </div>

            {!busToNav3Good?.id && !busToNav3Good?.length > 0 && <div style={{ margin: '10%' }}>--- העסק {inpNav3} לא נמצא ---</div>}
            {busToNav3Good?.id && busToNav3.length === 0 && <div style={{ margin: '10%' }}>אין מידע על סטטיסטיקה לעסק {busToNav3Good.name}.</div>}
            {busToNav3Good?.length > 0 && <div style={{ margin: '10%' }}>
                {busToNav3Good.map((x, i) => (
                    <div key={i} className='together' style={{ display: 'inline-block', margin: '3%', position: 'relative' }}>
                        <div className='buton text textSearch'
                            onClick={() => { nav3ChooseBus2(x) }}>
                            <ButtonRed text={x.name}></ButtonRed>
                        </div>

                        <RefReady tag={<span style={{ position: 'relative' }}>
                            <ImageDiv $pic={encodeURI(`${baseImageUrl}${x.mainImage?.replace(/\\/g, '\/')}?format=avif`)}
                                onClick={() => { nav3ChooseBus2(x) }}
                                className='image imageSearch'>פרטים נוספים<div className='border2'></div>
                                <div className='border3'></div><div className='black blackSearch' style={{ lineHeight: '30vh', backgroundColor: 'rgba(0, 0, 0, 0.31)' }}>פרטים נוספים</div></ImageDiv></span>
                        } event={'animate__fadeInDown'} event2={'animate__faster'}>
                        </RefReady>
                    </div>
                ))}
            </div>}

            {busToNav3.length > 0 && <table style={{ width: '90%', margin: '5%', textAlign: 'center' }} className="animate__animated animate__fadeInUp">
                <thead>
                    <tr>
                        <th style={{ fontWeight: '400', width: '10%', textAlign: 'center' }}></th>
                        <th style={{ fontWeight: '400', width: '20%', textAlign: 'center' }}>תאריך</th>
                        <th style={{ fontWeight: '400', width: '10%', textAlign: 'center' }}>כמות צפיות</th>
                        <th style={{ fontWeight: '400', width: '10%', textAlign: 'center' }}>כמות הקלקות</th>
                        <th style={{ fontWeight: '400', width: '10%', textAlign: 'center' }}>כמות האזנות</th>
                        <th style={{ fontWeight: '400', width: '10%', textAlign: 'center' }}>כמות פניות טלפוניות</th>
                        <th style={{ fontWeight: '400', width: '10%', textAlign: 'center' }}>כמות פניות במייל</th>
                        <th style={{ fontWeight: '400', width: '10%', textAlign: 'center' }}>כמות צפיות בדרייב</th>
                        <th style={{ fontWeight: '400', width: '10%', textAlign: 'center' }}>כמות צפיות באתר / בקליפ</th>
                        <th style={{ fontWeight: '400', width: '10%', textAlign: 'center' }}>כמה העסק נכנס לעריכה</th>
                    </tr>
                </thead>
                <tbody>
                    {busToNav3.map((how, i) => (
                        <tr style={{ fontWeight: '300', textAlign: 'center', borderTop: '#c00000 1px solid' }} key={i}>
                            <td style={{ textAlign: 'center', borderLeft: '#c00000 1px solid' }}>{i + 1}</td>
                            <td style={{ textAlign: 'center', direction: 'rtl', borderLeft: '#c00000 1px solid' }}>{how.date.split('T')[0]}</td>
                            <td style={{ textAlign: 'center' }}>{how.howSeeOutside}</td>
                            <td style={{ textAlign: 'center' }}>{how.howSee}</td>
                            <td style={{ textAlign: 'center' }}>{how.howHear}</td>
                            <td style={{ textAlign: 'center' }}>{how.howCall}</td>
                            <td style={{ textAlign: 'center' }}>{how.howSendMail}</td>
                            <td style={{ textAlign: 'center' }}>{how.howSeeDrive}</td>
                            <td style={{ textAlign: 'center' }}>{how.howSeeKishur}</td>
                            <td style={{ textAlign: 'center' }}>{how.howEditBussiness}</td>
                        </tr>
                    ))}
                </tbody>
            </table>}

        </div>}

        {nav === 4 && <div>
            <div style={{ fontSize: '150%', fontWeight: '500', marginTop: '8%', width: '100%', marginBottom: '-10px' }}>תוצאות מוצגות לשנת
                <input style={{ display: 'inline-block', width: '20%', fontSize: '80%', cursor: 'auto', margin: '0 20px' }} className='inpToNav3' type='text' defaultValue={new Date().getFullYear()} autoComplete="off" autoCapitalize="off" onKeyDown={(e) => { if (e.key === "Enter") { document.getElementsByClassName('inpToNav3')[0].classList.add('inpToNav3Enter') } }}></input> חודש:
                <input style={{ display: 'inline-block', width: '20%', fontSize: '80%', cursor: 'auto', margin: '0 20px' }} className='inpToNav3' type='text' placeholder='חודש' autoComplete="off" autoCapitalize="off" onKeyDown={(e) => { if (e.key === "Enter") { nav4ChooseMonth(document.getElementsByClassName('inpToNav3')[0].value, e.target.value) } }}></input>
                <p style={{ fontSize: '60%', display: 'inline-block' }}>({busToNav4.length} עסקים)</p>
            </div>

            {busToNav4.length === 0 && <div style={{ margin: '10%' }}>--- אין תוצאות לתאריך {new Date(parseInt(document.getElementsByClassName('inpToNav3')[0]?.value), parseInt(document.getElementsByClassName('inpToNav3')[1]?.value) - 1, 1).toLocaleDateString('he-IL')} ---</div>}

            {busToNav4.length > 0 && <table style={{ width: '90%', margin: '5%', textAlign: 'center' }} className="animate__animated animate__fadeInUp">
                <thead>
                    <tr>
                        <th style={{ fontWeight: '400', width: '10%', textAlign: 'center' }}></th>
                        <th style={{ fontWeight: '400', width: '20%', textAlign: 'center' }}>שם העסק</th>
                        <th style={{ fontWeight: '400', width: '10%', textAlign: 'center', cursor: 'pointer' }} onClick={() => { const sortedData = [...busToNav4].sort((a, b) => b.howSeeOutside - a.howSeeOutside); setBusToNav4(sortedData); }}>כמות צפיות</th>
                        <th style={{ fontWeight: '400', width: '10%', textAlign: 'center', cursor: 'pointer' }} onClick={() => { const sortedData = [...busToNav4].sort((a, b) => b.howSee - a.howSee); setBusToNav4(sortedData); }}>כמות הקלקות</th>
                        <th style={{ fontWeight: '400', width: '10%', textAlign: 'center', cursor: 'pointer' }} onClick={() => { const sortedData = [...busToNav4].sort((a, b) => b.howHear - a.howHear); setBusToNav4(sortedData); }}>כמות האזנות</th>
                        <th style={{ fontWeight: '400', width: '10%', textAlign: 'center', cursor: 'pointer' }} onClick={() => { const sortedData = [...busToNav4].sort((a, b) => b.howCall - a.howCall); setBusToNav4(sortedData); }}>כמות פניות טלפוניות</th>
                        <th style={{ fontWeight: '400', width: '10%', textAlign: 'center', cursor: 'pointer' }} onClick={() => { const sortedData = [...busToNav4].sort((a, b) => b.howSendMail - a.howSendMail); setBusToNav4(sortedData); }}>כמות פניות במייל</th>
                        <th style={{ fontWeight: '400', width: '10%', textAlign: 'center', cursor: 'pointer' }} onClick={() => { const sortedData = [...busToNav4].sort((a, b) => b.howSeeDrive - a.howSeeDrive); setBusToNav4(sortedData); }}>כמות צפיות בדרייב</th>
                        <th style={{ fontWeight: '400', width: '10%', textAlign: 'center', cursor: 'pointer' }} onClick={() => { const sortedData = [...busToNav4].sort((a, b) => b.howSeeKishur - a.howSeeKishur); setBusToNav4(sortedData); }}>כמות צפיות באתר / בקליפ</th>
                        <th style={{ fontWeight: '400', width: '10%', textAlign: 'center', cursor: 'pointer' }} onClick={() => { const sortedData = [...busToNav4].sort((a, b) => b.howEditBussiness - a.howEditBussiness); setBusToNav4(sortedData); }}>כמה העסק נכנס לעריכה</th>
                    </tr>
                </thead>
                <tbody>
                    {busToNav4.map((how, i) => (
                        <tr style={{ fontWeight: '300', textAlign: 'center', borderTop: '#c00000 1px solid' }} key={i}>
                            <td style={{ textAlign: 'center', borderLeft: '#c00000 1px solid' }}>{i + 1}</td>
                            <td style={{ textAlign: 'center', direction: 'rtl', borderLeft: '#c00000 1px solid' }}>{how.nameBus}</td>
                            <td style={{ textAlign: 'center' }}>{how.howSeeOutside}</td>
                            <td style={{ textAlign: 'center' }}>{how.howSee}</td>
                            <td style={{ textAlign: 'center' }}>{how.howHear}</td>
                            <td style={{ textAlign: 'center' }}>{how.howCall}</td>
                            <td style={{ textAlign: 'center' }}>{how.howSendMail}</td>
                            <td style={{ textAlign: 'center' }}>{how.howSeeDrive}</td>
                            <td style={{ textAlign: 'center' }}>{how.howSeeKishur}</td>
                            <td style={{ textAlign: 'center' }}>{how.howEditBussiness}</td>
                        </tr>
                    ))}
                </tbody>
            </table>}

        </div>}
        {/* {nav === 4 && <div><p style={{ fontSize: '150%', fontWeight: '500', marginTop: '8%', width: '100%', marginBottom: '-10px' }}>שלב ג' <p style={{ fontSize: '60%', display: 'inline-block' }}>({statistic.length} עסקים)</p></p>
            <RefReady tag={<div className='animate__animated' style={{ width: '70px', height: '4px', backgroundColor: '#c00000', marginLeft: 'auto', marginRight: 'auto', marginBottom: '3%' }}></div>} event={'animate__lightSpeedInRight'}></RefReady>

            שלב ג'<br></br>עדיין בהקמה...<br></br><br></br>

        </div>} */}


        {openShlucha && <div id='modal2'>
            <div className='animate__animated animate__zoomIn'>
                <CloseOutlined className='close2' onClick={() => { setOpenShlucha(false); setShlucha(''); setBus({}) }} style={{ cursor: 'pointer', position: 'absolute', left: '12%', marginTop: '1.5%' }} />
                <div className='glow' style={{ backgroundColor: 'white', width: '80%', height: '70vh', borderRadius: '20px 20px 20px 20px', border: '#c00000 3px solid', marginRight: '10%', marginTop: '15vh' }}>
                    <div style={{ margin: '3% auto' }} className='divButton divButton2' onClick={() => { GetOneBussiness(String(openShlucha.bussiness.categoryCode).split(',')[0], openShlucha.bussiness.id).then((data) => { setBus(data); console.log(data) }) }}>הצג את פרטי העסק בשביל מילוי השלוחה</div>
                    <div style={{ width: '90%', height: '35%', marginRight: '5%' }}>
                        {bus?.id && <div>
                            <div style={{ fontWeight: '400', display: 'inline-block', margin: '0' }}>שם עסק: </div><div style={{ display: 'inline-block', paddingRight: '5px' }}>{bus.name}</div><br />
                            <p style={{ fontWeight: '400', display: 'inline-block', margin: '0' }}>טלפון לקו: </p><div style={{ display: 'inline-block', paddingRight: '5px' }}>{bus.phoneToCall}</div><br />
                            <p style={{ fontWeight: '400', display: 'inline-block', margin: '0' }}>תיאור ויתרון: </p><div style={{ display: 'inline-block', paddingRight: '5px' }}>{bus.name} - {bus.description} {bus.adventage}</div><br />
                            {bus.address?.split(',.').length <= 1 && <div><p style={{ fontWeight: '400', display: 'inline-block', margin: '0 5px' }}>כתובת ושעות פתיחה: </p>שעות פתיחה: {bus.openingHours}. כתובת: {bus.address} {bus.city}<br /></div>}
                            {bus.address?.split(',.').length > 1 && <div><p style={{ fontWeight: '400', display: 'inline-block', margin: '0 5px' }}>כתובת ושעות פתיחה: </p>שעות פתיחה: {bus.openingHours}. סניפים: {bus.address?.split(',.').map((x, i) => (<span key={i}>{bus.address?.split(',.')[i]} - {x}, </span>))}<br /></div>}
                            {bus.place && <div><p style={{ fontWeight: '400', display: 'inline-block', margin: '0' }}>מיקום האולם: {bus.size}. האולם מכיל: {bus.place} מקומות. מחיר האולם: {bus.price} ש"ח.</p> {bus.rangeDishes && <span>טווח מנות: {bus.rangeDishes}.</span>} {bus.rangePriceToDishes && <span>טווח מחירים למנה: {bus.rangePriceToDishes} ש"ח.</span>}<br /></div>}
                            {bus.signon && <p style={{ fontWeight: '400', display: 'inline-block', margin: '0' }}>מחיר: {bus.price} ש"ח.  המחיר כולל: {bus.includePrice}. אזור פעילות: {bus.area}. סגנון מוזיקה: {bus.signon}. <br /></p>}
                            {bus.rangePrice && <p style={{ fontWeight: '400', display: 'inline-block', margin: '0' }}>צלמים --- מחיר לחבילת צילום רגילה: {bus.rangeDishes} ש"ח. המחיר כולל: {bus.rangeDishesKabalat}. אזור פעילות: {bus.rangePrice}.<br /></p>}
                            {bus.rangePrice && <p style={{ fontWeight: '400', display: 'inline-block', margin: '0' }}>קייטרינג רגיל --- מספר מנות סעודה: {bus.rangeDishes}. מספר מנות קבלת פנים: {bus.rangeDishesKabalat}. טווח מחירים למנת סעודה: {bus.rangePrice} ש"ח.<br /></p>}
                            {bus.rangePrice && <p style={{ fontWeight: '400', display: 'inline-block', margin: '0' }}>קייטרינג בר פירות / בר עוגות ומתוקים --- מספר מגשים: {bus.rangeDishesKabalat}. טווח מחיר למגש: {bus.rangePrice} ש"ח.<br /></p>}

                            <div><p style={{ fontWeight: '400', display: 'inline-block', margin: '0 5px' }}>מבצעים: </p>{bus.bonus}<br /></div>
                        </div>}
                    </div>
                    <div className='titl' style={{ fontSize: '120%', marginBottom: '0' }}>מהו מספר השלוחה?</div>
                    <input className='input20 ii' style={{ width: '60%', direction: 'ltr' }} autoComplete="on" type="text" value={shlucha} onChange={(e) => { if (/^[0-9 >]*$/.test(e.target.value) || e.target.value === '') { setShlucha(e.target.value) } }} />
                    <div className='buton1' style={{ width: '10%', marginRight: 'auto', marginLeft: 'auto', marginTop: '1%' }} onClick={() => { EditNumToCall(String(openShlucha.bussiness.categoryCode).split(',')[0], openShlucha.bussiness.id, shlucha != '' ? shlucha : null).then((data) => { var arr = statistic; arr[openShlucha.i].numberToCall = shlucha; setStatistic(arr); d(COMmanaggerSTATISTIC(arr)); setMailForOpenShlucha('') }).catch(error => console.log(error)) }}><ButtonRed text='אישור'></ButtonRed></div>
                </div>
            </div>
        </div>}

        {mailForOpenShlucha != -1 && <div id='modal2'>
            <div className='animate__animated animate__zoomIn'>
                <CloseOutlined className='close2' onClick={() => { setMailForOpenShlucha(-1); }} />
                <div className='glow'>
                    <div className='titl' style={{ marginTop: '5%', marginBottom: '3%' }}>האם לשלוח הודעה לעסק שהתחבר למערכת הטלפונית?</div>
                    <input className='input20 ii' style={{ width: '60%', marginBottom: '2%' }} placeholder="אם יש הערות לעסק - יש לכתוב כאן" type="text" value={mailForOpenShlucha} onChange={(e) => { setMailForOpenShlucha(e.target.value) }} />
                    <p><b>דוגמה להערה: </b>כרגע לא הכנסתם כתובת ושעות פתיחה, מומלץ לעדכן את כל הפרטים כדי להשיג את התועלת המירבית מהפרסום.</p>
                    <div className='buton' onClick={() => {
                        var mes = `<div style="font-family: Calibri, Rubik, Tahoma, Comic Sans MS, sans-serif; font-size: 24px; direction: rtl; padding-bottom: 10vh; text-align: center; background-color: rgb(245, 239, 239);"><div style="background-color: rgb(232, 219, 219); padding: 20px; padding-right: 20%; padding-left: 20%; border-top: #c00000 1px solid; border-bottom: #c00000 1px solid;"><p style="font-size: 23px;">הקשיבו ובדקו את השלוחה שלכם</p></div><br/> <br/>שלום ל${bus.name} <br/>בניית השלוחה שלכם במערכת הטלפונית הושלמה בהצלחה <br/>ממליצים להתקשר ולשמוע: <br/><div style="direction: ltr;"><b style="font-size: 120%; line-height: 170%;">02-3137663 > ${shlucha}</b></div>ולבדוק שהכל תקין.<br/>${mailForOpenShlucha}<br/>השלוחה נבנתה עבורכם חינם.<br/>ניתן לשלוח שינויים / תיקונים תוך 5 ימים.<br/><br/><b>קול שמחה - הכל לשמחה</b> <br/> <br/> <a href="https://kolsimcha.co.il" style="text-decoration: none; background: linear-gradient(90deg, #c00000, #e57373); color: white; padding: 15px 30px; font-weight: bold; border-radius: 5px; box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);">מעבר לאתר</a> <br/></div>`
                        SendEmailToBussiness({ name: "", emailTo: bus.mail, emailFrom: 'info@kolsimcha.co.il', subject: `הצטרפתם בהצלחה לקו הקולי של קול שמחה`, message: mes }).then(() => {
                            setAlert(true); setTimeout(() => { setAlert(false) }, 3800);
                            setOpenShlucha(false); setShlucha(''); setBus({}); setMailForOpenShlucha(-1)
                        }).catch((error) => { console.error(error); });
                    }} style={{ width: '20%', marginRight: 'auto', marginLeft: 'auto', display: 'inline-block' }}><ButtonRed text='שלח מייל'></ButtonRed></div>
                    <div className='buton' onClick={() => {
                        setOpenShlucha(false); setShlucha(''); setBus({}); setMailForOpenShlucha(-1)
                    }} style={{ width: '20%', marginRight: 'auto', marginLeft: 'auto', display: 'inline-block' }}><ButtonRed text='לא, אל תשלח'></ButtonRed></div>
                </div>
            </div>
        </div>}

        {openTdHowManyCall && <div id='modal2'>
            <div className='animate__animated animate__zoomIn'>
                <CloseOutlined className='close2' onClick={() => setOpenTdHowManyCall(false)} style={{ cursor: 'pointer', position: 'absolute', left: '12%', marginTop: '1.5%' }} />
                <div className='glow' style={{ backgroundColor: 'white', width: '80%', height: '50vh', borderRadius: '20px 20px 20px 20px', border: '#c00000 3px solid', marginRight: '10%', marginTop: '25vh' }}>
                    <div className='titl' style={{ marginTop: '6%', fontSize: '120%', marginBottom: '5%' }}>הכנס מספר - ככל שהמספר יותר גבוה - העסק יוצג למעלה יותר:</div>
                    <input className='input20 ii' style={{ width: '60%' }} autoComplete="on" type="text" value={openTdHowManyCall.orderBy} onChange={(e) => { if ((/^[0-9]*$/.test(e.target.value) && e.target.value <= 32767) || e.target.value === '') { setOpenTdHowManyCall({ ...openTdHowManyCall, orderBy: e.target.value }) } }} />
                    <div className='buton1' style={{ width: '10%', marginRight: 'auto', marginLeft: 'auto', marginTop: '4%' }} onClick={() => { EditHowManyCall(String(openTdHowManyCall.bussiness.categoryCode).split(',')[0], openTdHowManyCall.bussiness.id, openTdHowManyCall.orderBy).then((data) => { var arr = statistic; arr[openTdHowManyCall.i].orderBy = openTdHowManyCall.orderBy; setStatistic(arr); d(COMmanaggerSTATISTIC(arr)); setOpenTdHowManyCall(false); }).catch(error => console.log(error)) }}><ButtonRed text='אישור'></ButtonRed></div>
                </div>
            </div>
        </div>}

        {openPhoneToCall && <div id='modal2'>
            <div className='animate__animated animate__zoomIn'>
                <CloseOutlined className='close2' onClick={() => setOpenPhoneToCall(false)} style={{ cursor: 'pointer', position: 'absolute', left: '12%', marginTop: '1.5%' }} />
                <div className='glow' style={{ backgroundColor: 'white', width: '80%', height: '50vh', borderRadius: '20px 20px 20px 20px', border: '#c00000 3px solid', marginRight: '10%', marginTop: '25vh' }}>
                    <div className='titl' style={{ marginTop: '9%', fontSize: '120%', marginBottom: '5%' }}>הורדת העסק מחבילת קול שמחה לחבילת קול ששון</div>
                    <div className='buton1' style={{ width: '10%', marginRight: 'auto', marginLeft: 'auto', marginTop: '4%' }} onClick={() => { EditPhoneToCall(String(openPhoneToCall.bussiness.categoryCode).split(',')[0], openPhoneToCall.bussiness.id, null).then((data) => { var arr = statistic; arr[openPhoneToCall.i].phoneToCall = ""; setStatistic(arr); d(COMmanaggerSTATISTIC(arr)); setOpenPhoneToCall(false); }).catch(error => console.log(error)) }}><ButtonRed text='אישור'></ButtonRed></div>
                </div>
            </div>
        </div>}

        {openCode && <div id='modal2'>
            <div className='animate__animated animate__zoomIn'>
                <CloseOutlined className='close2' onClick={() => { setOpenCode(false); setCode('') }} style={{ cursor: 'pointer', position: 'absolute', left: '12%', marginTop: '1.5%' }} />
                <div className='glow' style={{ backgroundColor: 'white', width: '80%', height: '50vh', borderRadius: '20px 20px 20px 20px', border: '#c00000 3px solid', marginRight: '10%', marginTop: '25vh' }}>
                    <div className='titl' style={{ marginTop: '6%', fontSize: '120%', marginBottom: '5%' }}>הכנס קוד לניהול העסק</div>
                    <input className='input20 ii' style={{ width: '60%' }} autoComplete="on" type="text" value={code} onChange={(e) => { if ((/^[0-9]*$/.test(e.target.value) && e.target.value.length <= 9) || e.target.value === '') { setCode(e.target.value) } }} />
                    <div className='buton1' style={{ width: '10%', marginRight: 'auto', marginLeft: 'auto', marginTop: '4%' }} onClick={() => { if (code !== '') { ChangePassBussiness(String(openCode.bussiness.categoryCode).split(',')[0], openCode.bussiness.id, code).then((data) => { if (data === true) { var b = openCode.bussiness; b.code = code; d(CompleteThisBussiness(b)); var mes = `<div style="font-family: Calibri, Rubik, Tahoma, Comic Sans MS, sans-serif; font-size: 24px; direction: rtl; padding-bottom: 10vh; text-align: center; background-color: rgb(245, 239, 239);"><div style="background-color: rgb(232, 219, 219); padding: 20px; padding-right: 20%; padding-left: 20%; border-top: #c00000 1px solid; border-bottom: #c00000 1px solid;"><p style="font-size: 130%;"> שלום ל${b.name} </p></div><br/><br/> הצטרפתם מחדש למערכת קול שמחה <br/> <br/><b>סיסמתכם החדשה היא: </b> <br/> ${code} <br/> <br/>  <b>קול שמחה - הכל לשמחה</b> <br/> <br/> <a href="https://kolsimcha.co.il" style="text-decoration: none; background: linear-gradient(90deg, #c00000, #e57373); color: white; padding: 15px 30px; font-weight: bold; border-radius: 5px; box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);">מעבר לאתר</a> <br/></div>`; SendEmailToBussiness({ name: "", emailTo: b.mail, emailFrom: 'info@kolsimcha.co.il', subject: 'הצטרפתם מחדש למיזם קול שמחה', message: mes }).then(() => { }).catch((error) => { console.error(error); }); setOpenCode(false); setCode('') } }).catch((error) => { console.error(error); }); } }}><ButtonRed text='אישור'></ButtonRed></div>
                </div>
            </div>
        </div>}

        {openDel && <div id='modal2'>
            <div className='animate__animated animate__zoomIn'>
                <CloseOutlined className='close2' onClick={() => setOpenDel(false)} style={{ cursor: 'pointer', position: 'absolute', left: '12%', marginTop: '1.5%' }} />
                <div className='glow' style={{ backgroundColor: 'white', width: '80%', height: '40vh', borderRadius: '20px 20px 20px 20px', border: '#c00000 3px solid', marginRight: '10%', marginTop: '25vh' }}>
                    <div className='titl' style={{ marginTop: '6%', fontSize: '120%', marginBottom: '2%' }}>האם אתה בטוח שברצונך למחוק עסק זה?</div>
                    <div className='buton1' style={{ width: '20%', marginRight: 'auto', marginLeft: '1%', marginTop: '4%', display: 'inline-block' }} onClick={() => { setOpenDel(false) }}><ButtonRed text='ביטול מחיקה'></ButtonRed></div>
                    <div className='buton1' style={{ width: '20%', marginRight: '1%', marginLeft: 'auto', marginTop: '4%', display: 'inline-block' }} onClick={() => { ChangePassBussiness(String(openDel.bussiness.categoryCode).split(',')[0], openDel.bussiness.id, -1).then((data) => { if (data === true) { var b = openDel.bussiness; b.code = -1; d(CompleteThisBussiness(b)); setOpenDel(false) } }).catch((error) => { console.error(error); }); }}><ButtonRed text='אישור מחיקה'></ButtonRed></div>
                </div>
            </div>
        </div>}

        {alert && <div><Alert style={{ position: 'fixed' }} message="המייל נשלח בהצלחה" className="alert animate__animated animate__fadeInDown" /><img loading='lazy' style={{ position: 'fixed' }} src={pv} className="pv animate__animated animate__fadeInDown"></img></div>}

    </div>
}

export default Statistics